<template>
    <div>
        <div class="uploader" v-if="!showUploadForm">
            <div class="upload-body"
                @click="clickUpload"
                @dragenter="handleDragenter"
                @dragleave="handleDragleave"
                @dragover.prevent=""
                @drop.prevent="handleDrop">
                <div class="upload-btn"></div>
                <div class="upload-desc">点击上传 或直接将视频文件拖入此区域</div>
                <input id="fileUpload" type="file" style="display:none;" @change="fileChange($event)">
            </div>
            <div class="upload-tips">
                <div class="tips-item">
                    <div class="item-title">视频大小</div>
                    <div class="item-content">时长在15分钟以内，大小不超过4G</div>
                </div>
                <div class="tips-item">
                    <div class="item-title">视频格式</div>
                    <div class="item-content">支持常用视频格式，推荐使用mp4</div>
                </div>
                <div class="tips-item">
                    <div class="item-title">视频分辨率</div>
                    <div class="item-content">分辨率为720p（1280x720）以上</div>
                </div>
            </div>
        </div>
        <!--视频发布表单-->
        <div class="upload-detail" v-else>
            <div class="upload-form">
                <form-item label="视频描述">
                    <qilv-input :limit="120" type="textarea" placeholder="添加合适的话题与描述，作品能获得更多的推荐～" v-model="uploadFormData.title"></qilv-input>
                </form-item>
                <form-item id="location-list" label="添加地点" style="position:relative;">
                    <qilv-input
                        style="margin-bottom:4px;"
                        type="text"
                        placeholder="输入相关位置，让更多人看到你的作品"
                        v-model="searchLocationString"
                        icon-class="icon-position"
                        @focus="onSearchLocationFocus"
                        @keyup="onSearchLocationKeyup"></qilv-input>
                    <div class="drop-down-list" v-if="showLocationList">
                        <div class="drop-down-item" v-if="searchLocationResults.length === 0">
                            <div class="location-name">没有搜索到结果</div>
                        </div>
                        <div class="drop-down-item"
                            @click="onLocationItemClick(poi)"
                            v-for="(poi, index) in searchLocationResults"
                            :key="index">
                            <div class="location-name">{{ poi.name }}</div>
                            <div class="location-address">{{ poi.address }}</div>
                        </div>
                    </div>
                </form-item>
                <form-item label="查看权限">
                    <ql-radio-group v-model="uploadFormData.scope">
                        <ql-radio :label="0">公开</ql-radio>
                        <ql-radio :label="1">仅自己可见</ql-radio>
                    </ql-radio-group>
                </form-item>
                <div class="form-footer">
                    <ql-button type="primary" width="120" @click="publish">发布</ql-button>
                    <ql-button :disabled="resetDisabled" width="120" @click="reset">取消</ql-button>
                </div>
            </div>
            <div class="upload-status">
                <div class="preview"></div>
                <div class="progress-marker"></div>
                <div class="status-controller">
                    <div class="progress">0%</div>
                    <div class="state">{{ uploadStateText }}</div>
                    <div class="btn-cancel" @click="cancelUpload" v-if="showCancelButton">取消上传</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Message from '../../components/message'

    export default {
        name: "AscUploader",
        data() {
            return {
                location: {
                    latitude: null,
                    longitude: null,
                    cityName: null,
                    cityCode: null,
                },
                region: 'cn-shanghai',
                userId: '8',
                uploader: null,
                maxSize: 4 * 1024 * 1024 * 1024,    // 限制上传大小为4G
                resetDisabled: false,               // 是否禁用表单的取消按钮
                searchLocationString: null,         // 搜索地点的关键字
                searchLocationResults: [],
                searchLocationTimeId: null,
                searchLocationTimeout: 1000,        // 地点即时搜索请求延时，单位：毫秒。
                showLocationList: false,
                showUploadForm: false,              // 是否显示上传进度和表单
                showCancelButton: true,             // 是否显示取消上传按钮
                onBodyClick: null,
                uploadFormData: {                   // 提交上传的视频数据
                    title: null,                    // 视频标题
                    location: null,                 // 视频定位名称
                    latitude: null,                 // 视频定位经度坐标
                    longitude: null,                // 视频定位纬度坐标
                    scope: 0,                       // 视频的权限范围：0=公开；1=私有。
                    video_id: null,                 // 视频上传到阿里云的 video_id
                },
                uploadStateText: '视频上传中',
            }
        },
        methods: {
            cancelUpload() {
                if (this.uploader != null)
                    this.uploader.cancelFile(0)
            },
            checkFile(file) {
                if (!file) return false
                // 判断文件格式限制
                if (!/^video\/.*/.test(file.type)) {
                    this.$message.error('上传文件格式错误！')
                    return false
                }
                // 判断文件大小限制
                if (file.size > this.maxSize) {
                    this.$message.error('文件大小超过限制！')
                    return false
                }
                return true
            },
            clickUpload() {
                // 直接触发上传控件的点击事件。
                document.getElementById('fileUpload').click()
            },
            createUploader() {
                let vm = this
                let uploader = new window.AliyunUpload.Vod({
                    // 其它配置全部使用默认值
                    region: vm.region,
                    userId: vm.userId,
                    onUploadstarted: function (uploadInfo) {
                        // vm.showUploadForm = true                 // 上传开始后显示上传表单
                        vm.resetDisabled = true                  // 上传开始后禁用表单取消功能
                        vm.showCancelButton = true               // 上传开始后需要显示取消上传按钮
                        vm.getSTSToken().then(({data}) => {
                            uploader.setSTSToken(uploadInfo, data.AccessKeyId, data.AccessKeySecret, data.SecurityToken)
                        })
                    },
                    // 上传成功更新 videoId
                    onUploadSucceed: function (uploadInfo) {
                        vm.uploadFormData.video_id = uploadInfo.videoId
                    },
                    onUploadFailed: function (uploadInfo, code, message) {
                        console.warn('Upload Failed: ' + message)
                        vm.uploader = null
                    },
                    onUploadCanceled: function () {
                        vm.resetDisabled = false
                        vm.$message("已取消上传")
                    },
                    onUploadProgress: function (uploadInfo, totalSize, progress) {
                        vm.updateProgress(progress)
                    },
                    onUploadTokenExpired: function (uploadInfo) {
                        vm.getSTSToken().then(({data}) => {
                            uploader.setSTSToken(uploadInfo, data.AccessKeyId, data.AccessKeySecret, data.SecurityToken)
                        })
                    },
                    // 上传完成后销毁 Uploader 对象。
                    onUploadEnd: function () {
                        console.log('uploadFinished!')
                        vm.uploadStateText = '上传完成'
                        vm.resetDisabled = false        // 上传完成后可以点击表单取消按钮
                        vm.showCancelButton = false     // 上传完成后就不显示取消上传了
                        this.uploader = null            // 销毁控件，节约内存
                    },
                })
                return uploader
            },
            fileChange(event) {
                try {
                    let file = event.target.files[0]
                    if (!this.checkFile(file)) return
                    this.setVideoCover(file)            // 设置视频封面
                    this.startUpload(file)              // 开始上传
                } catch (e) {
                    console.warn(e.message)
                } finally {
                    // 置空上传控件值，防止重复上传同一文件时不触发 change 事件
                    event.target.value = ''
                }
            },
            getGeoLocationByAPI() {
                this.$http.get('/api/location/ip').then(res => {
                    if (res.data.status === 0) {
                        let data = res.data.content
                        this.location.city = data.address_detail.city
                        this.location.cityCode = data.address_detail.city_code
                        this.location.latitude = data.point.y
                        this.location.longitude = data.point.x
                    } else {
                        console.warn('获取定位失败：' + res.data.message)
                        this.location.cityCode = '全国'
                    }
                })
            },
            getSTSToken() {
                return this.$http.get('/api/auth/aliyun_sts')
            },
            getLocationInfo() {
                if (this.location.latitude == null || this.location.longitude == null) {
                    if ('geolocation' in navigator) {
                        navigator.geolocation.getCurrentPosition(position => {
                            this.location.latitude = position.coords.latitude
                            this.location.longitude = position.coords.longitude
                            // 请求后端逆地理编码获取城市名称和 city_code
                            this.$http.get("/api/location/map/baidu/reverse_geocoding", {
                                params: {
                                    latitude: position.coords.latitude,
                                    longitude: position.coords.longitude
                                }
                            }).then(res => {
                                if (res.data.status === 0) {
                                    this.location.cityName = res.data.result.addressComponent.city
                                    this.location.cityCode = res.data.result.cityCode
                                }
                            })
                        }, error => {
                            console.warn(error.message) // 用户可能会禁止定位权限
                            this.getGeoLocationByAPI()
                        })
                    } else {
                        this.getGeoLocationByAPI()
                    }
                }
            },
            searchLocation() {
                if (this.isEmpty(this.searchLocationString)) return

                this.$http.get('/api/location/map/baidu/district_search', {
                    params: {
                        query: this.searchLocationString,
                        region: this.location.cityCode || "全国"
                    }
                }).then(res => {
                    if (res.data.status === 0 && res.data.results.length > 0) {
                        let results = []
                        res.data.results.forEach(item => {
                            results.push({
                                name: item.name,
                                address: item.address,
                                latitude: item.location.lat.toString(),
                                longitude: item.location.lng.toString()
                            })
                        })
                        this.searchLocationResults = results
                    } else {
                        this.searchLocationResults = []
                    }
                }).catch(error => {
                    console.warn(error.message)
                    this.searchLocationResults = []
                }).finally(() => {
                    this.showLocationList = true
                })
            },
            setVideoCover(file) {
                this.showUploadForm = true                          //上传开始后显示上传表单
                let video = document.createElement('video')
                video.width = 225
                video.height = 400
                video.src = URL.createObjectURL(file)
                video.currentTime = 1
                video.addEventListener('loadeddata', () => {
                    let canvas = document.createElement('canvas')
                    canvas.width = video.width
                    canvas.height = video.height
                    canvas.getContext('2d').drawImage(video, 0, 0, video.width, video.height)
                    this.$el.querySelector('.preview').style.backgroundImage = "url('" + canvas.toDataURL('image/jpeg') + "')"
                })
            },
            startUpload(file) {
                if (this.uploader) {
                    this.uploader.stopUpload()
                }
                this.uploader = this.createUploader()
                this.uploader.addFile(file, null, null, null, '{"Vod": {}}')
                this.uploader.startUpload()
            },
            handleDragenter(evt) {
                evt.currentTarget.classList.add('dragover')
            },
            handleDragleave(evt) {
                evt.currentTarget.classList.remove('dragover')
            },
            handleDrop(evt) {
                try {
                    let file = evt.dataTransfer.files[0]
                    if (!this.checkFile(file)) return
                    if (this.uploader) {
                        this.uploader.stopUpload()
                    }
                    this.uploader = this.createUploader()
                    this.uploader.addFile(file, null, null, null, '{"Vod": {}}')
                    this.uploader.startUpload()
                } catch (e) {
                    console.warn(e.message)
                } finally {
                    evt.currentTarget.classList.remove('dragover')
                }
            },
            onLocationItemClick(poi) {
                this.showLocationList = false
                this.searchLocationString = poi.name
                this.uploadFormData.location = poi.name
                this.uploadFormData.latitude = poi.latitude.toString()
                this.uploadFormData.longitude = poi.longitude.toString()
            },
            onSearchLocationFocus() {
                if (this.uploadFormData.location != null) {
                    if (this.searchLocationResults.length > 0) {
                        this.showLocationList = true
                    } else {
                        this.searchLocation()
                    }
                } else {
                    this.showLocationList = false
                }
            },
            // 视频发布地点即时搜索方法。
            onSearchLocationKeyup() {
                // 清除延时效果。
                clearTimeout(this.searchLocationTimeId)
                // 先隐藏下拉列表。
                this.showLocationList = false
                // 延时发出请求。
                this.searchLocationTimeId = setTimeout(() => {
                    this.searchLocation()
                }, this.searchLocationTimeout)
            },
            publish() {
                // 验证提交的数据
                if (this.uploadFormData.video_id == null) {
                    this.$message.error('视频没有上传成功，请重新上传后再提交！')
                    return
                } else if (this.uploadFormData.location == null
                    || this.uploadFormData.latitude == null
                    || this.uploadFormData.longitude == null) {
                    this.$message.error('视频地点不能为空！')
                    return
                } else if (this.uploadFormData.title == null) {
                    this.$message.error('视频描述不能为空！')
                    return
                }
                this.$http.post('/api/ugsv/video', this.uploadFormData, {
                    headers: {'Accept': 'application/vnd.aiqilv.v2+json'}
                }).then(() => {
                    this.$router.push({name: 'videoList'})
                }).catch(error => {
                    console.error(error)
                })
                // TODO 1. 没有考虑视频音乐隔离的问题；2. 没有考虑没有地点的问题；3. 没有考虑话题标签的问题。
                // 必要的字段：title video_id latitude longitude
            },
            reset() {
                this.showUploadForm = false
                Object.assign(this.uploadFormData, {
                    desc: null,
                    location: null,
                    latitude: null,
                    longitude: null,
                    scope: 0,
                    video_id: null,
                })
            },
            updateProgress(progress) {
                let percent = Math.floor(progress * 100)
                this.$el.querySelector('.progress').innerText = percent + '%'
                this.$el.querySelector('.progress-marker').style.height = (100 - percent) + '%'
            }
        },
        mounted() {
            this.$message = new Message({target: '.main-container'})

            // 获取定位信息。
            this.getLocationInfo()

            // 绑定点击地点下拉列表以外的元素时，隐藏地点下拉列表。
            let $locationList = document.getElementById('location-list')
            this.onBodyClick = event => {
                this.showLocationList = this.showLocationList && $locationList.contains(event.target)
            }
            window.document.body.addEventListener('click', this.onBodyClick)
        },
        destroyed() {
            // 组件销毁时解除绑定事件，避免重复绑定。
            window.document.body.removeEventListener('click', this.onBodyClick)
        }
    }
</script>

<style scoped lang="less">
    .uploader {
        display:flex;
        flex-direction:column;
        flex-grow:1;
        padding:32px 40px 40px 40px;
        .upload-body {
            height:360px;
            display:flex;
            flex-direction:column;
            justify-content:center;
            align-items:center;
            margin-bottom:10px;
            background-color:#F5F5F5;
            border-radius:10px;
            border:1px dashed #979797;
            cursor:pointer;
            &:active, &.dragover {background-color:#FFEDEB;}
            .upload-btn {
                width:80px;
                height:80px;
                background-image:url("../../assets/images/icons/ic_Upload.png");
                background-size:100% 100%;
            }
            .upload-desc {
                font-size:14px;
                font-weight:700;
                user-select:none;
                margin-top:-15px;
            }
        }
        .upload-tips {
            display:flex;
            gap:0 10px;
            .tips-item {
                height:100px;
                padding:16px;
                display:flex;
                flex:1;
                flex-direction:column;
                justify-content:left;
                background-color:#F5F5F5;
                border-radius:10px;
                user-select:none;
                .item-title {
                    font-size:14px;
                    font-weight:700;
                    margin-bottom:2px;
                }
                .item-content {
                    font-size:12px;
                    font-weight:400;
                    color:#858585;
                }
            }
        }
    }
    .upload-detail {
        display:flex;
        align-items:center;
        justify-content:space-between;
        padding:0 40px;
        .upload-form {
            width:458px;
            .drop-down-list {
                width:100%;
                max-height:200px;
                border-radius:8px;
                box-shadow:0 6px 16px 0 rgba(0, 0, 0, 0.12);
                overflow-x:hidden;
                overflow-y:scroll;
                position:absolute;
                background-color:white;
                .drop-down-item {
                    cursor:pointer;
                    padding:16px 33px;
                    &:hover {
                        background-color:#F5F5F5;
                    }
                    .location-name {
                        font-size:14px;
                        color:#333333;
                        line-height:20px;
                    }
                    .location-address {
                        font-size:13px;
                        color:#ADADB1;
                        line-height:18px;
                    }
                }
            }
            .form-footer {
                border-top:1px solid #F0F0F0;
                margin-top:44px;
                padding:56px 0 40px 0;
            }
        }
        .upload-status {
            width:225px;
            height:400px;
            background-color:black;
            color:white;
            border-radius:12px;
            overflow:hidden;
            position:relative;
            .preview {
                width:100%;
                height:100%;
                background-position:100% 100%;
            }
            .progress-marker {
                width:100%;
                height:100%;
                position:absolute;
                left:0;
                top:0;
                background-color:rgba(0, 0, 0, .5);
            }
            .status-controller {
                border-radius:12px;
                background-color:rgba(0, 0, 0, .5);
                color:#FFFFFF;
                text-align:center;
                width:60%;
                padding:15px 0;
                position:absolute;
                left:50%;
                margin-left:-66px;
                bottom:24px;
                .progress {
                    font-size:20px;
                    line-height:24px;
                }
                .state {
                    margin-top:8px;
                    margin-bottom:20px;
                }
                .btn-cancel {
                    color:#ADADB1;
                    cursor:pointer;
                    font-size:12px;
                }
            }
        }
    }
</style>
